// fonts
$basefont: "Inter", sans-serif;
$secondaryfont: "Poppins", "Inter", sans-serif;
$otherfont: "Montserrat", "Poppins", "Inter", sans-serif;
// colors
$white: white;
$black: black;
$layout: #070A0E;
$baseclr: #DA41DD;

$borderclr: #362244;
$borderclr2: #393D42;

$gradient1: linear-gradient(180deg, #291B2B 0%, #161119 86.42%);
$gradient2: linear-gradient(177.68deg, #4C294F 1.95%, rgba(39, 30, 45, 0.1) 108.8%);
$gradient3: linear-gradient(180deg, #291B2B 0%, #11141966 86.42%);