@import "../../../../assets/theme/_var.scss";

.features {
    padding: 7.5rem 0 8rem;

    @media (max-width: 1579px) {
        padding: 5rem 0;
    }

    .features_in {

        h2,
        .sub_text {
            text-align: center;
        }

        .sub_text {
            margin: 2rem auto 5rem;
            max-width: 58.7rem;
            width: 100%;

            @media (max-width: 1579px) {
                margin: 1.6rem auto 3rem;
            }

            @media (max-width: 1479px) {
                margin: 1.4rem auto 2.4rem;
            }
        }

        .row {
            margin: -1.5rem;

            >div {
                padding: 1.5rem;
            }

            @media (max-width: 1579px) {
                margin: -.75rem;

                >div {
                    padding: .75rem;
                }
            }
        }

        .feature_box {
            background: $gradient3;
            padding: 4.9rem 4rem 4rem;
            border-radius: 2rem;
            height: 100%;

            @media (max-width: 1579px) {
                padding: 3.6rem;
            }

            @media (max-width: 1479px) {
                padding: 2.4rem;
            }

            @media (max-width: 575px) {
                text-align: center;
            }

            >img {
                width: 5.8rem;
                height: 5.8rem;
                object-fit: contain;
                object-position: center;

                @media (max-width: 1579px) {
                    width: 5rem;
                    height: 5rem;
                }
            }

            h4 {
                margin: 2.5rem 0 1rem;
                font-family: $secondaryfont;
                font-size: 2.2rem;
                font-weight: 600;
                line-height: 3.3rem;

                @media (max-width: 1579px) {
                    margin: 2rem 0 0.8rem;
                    font-size: 2rem;
                    line-height: 3rem;
                }

                @media (max-width: 1479px) {
                    font-size: 1.8rem;
                    line-height: 2.8rem;
                }
            }

            p {
                font-family: $secondaryfont;
                font-size: 1.6rem;
                font-weight: 400;
                line-height: 2.6rem;
                color: rgba($white, 0.8);

                @media (max-width: 1579px) {
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                }
            }
        }
    }
}