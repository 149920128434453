@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "./assets/theme/_var.scss";

html {
    font-size: 62.5%;
}

body {
    font-family: $basefont;
    color: $white;
    background-color: $layout;
}

img,
svg,
iframe,
canvas,
video {
    max-width: 100%;
}

p,
.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
    margin-bottom: 0;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

a,
span {
    display: inline-block;
    text-decoration: none;
}

.container,
.container-fluid {
    padding: 0 1.5rem;
}

.container {
    max-width: 142.6rem;

    @media (max-width: 1679px) {
        max-width: 122rem;
    }

    @media (max-width: 1479px) {
        max-width: 112rem;
    }

    @media (max-width: 1199px) {
        max-width: 92rem;
    }
}

html.lenis,
html.lenis body {
    height: auto;
}

.lenis.lenis-smooth {
    scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
}

.lenis.lenis-stopped {
    overflow: hidden;
}

.lenis.lenis-smooth iframe {
    pointer-events: none;
}