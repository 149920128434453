@import "../../../assets/theme/_var.scss";

.custom_btn {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2.4rem;
    width: 15.8rem;
    border-radius: 5rem;
    color: $white;
    height: 5rem;
    background-color: $baseclr;
    border: 2px solid $baseclr;
    transition: 300ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media (max-width: 1199px) {
        height: 4rem;
        width: 12rem;
        font-size: 1.2rem;
    }

    &:hover {
        color: $baseclr;
        background-color: transparent;
    }
}