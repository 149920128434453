@import "../../../../assets/theme/_var.scss";

.offering {
    padding: 4.9rem 0 7.5rem;

    @media (max-width: 1579px) {
        padding: 5rem 0;
    }

    @media (max-width: 991px) {
        padding: 4rem 0;
    }

    .offering_in {
        display: flex;
        align-items: center;
        position: relative;

        @media (max-width: 767px) {
            flex-direction: column;
        }

        .offering_graphic {
            position: absolute;
            top: calc(50% + 5rem);
            left: -26rem;
            width: 100%;
            transform: translateY(-50%);
            pointer-events: none;

            @media (max-width: 991px) {
                left: -20rem;
            }

            @media (max-width: 767px) {
                left: 50%;
                transform: translate(-50%, -16rem);
                width: 64.5rem;
                top: 0;
            }
        }

        .offering_text {
            width: 100%;
            max-width: 50.1rem;
            margin-left: auto;

            @media (max-width: 1479px) {
                max-width: 42rem;
            }

            @media (max-width: 767px) {
                margin: 24rem auto 0;

                h2,
                .sub_text {
                    text-align: center;
                }
            }

            ul {
                li {
                    &:not(:last-child) {
                        margin-bottom: 4rem;

                        @media (max-width: 1479px) {
                            margin-bottom: 3.4rem;
                        }
                    }

                    .offering_item {
                        display: flex;
                        align-items: center;

                        @media (max-width: 419px) {
                            flex-direction: column;
                            text-align: center;
                        }

                        .offering_item_icon {
                            border: 1px solid transparent;
                            border-radius: 2rem;
                            background: $gradient2 padding-box, linear-gradient(176.46deg, rgba($white, 0.4) 2.91%, rgba(0, 0, 0, 1) 100.38%) border-box;
                            width: 10rem;
                            height: 10rem;
                            display: flex;
                            align-items: center;
                            flex-shrink: 0;
                            margin-right: 3rem;
                            justify-content: center;

                            @media (max-width: 1579px) {
                                width: 8.8rem;
                                height: 8.8rem;
                            }

                            @media (max-width: 1479px) {
                                width: 6rem;
                                height: 6rem;
                                border-radius: 1.6rem;
                                padding: 1.4rem;
                            }

                            @media (max-width: 419px) {
                                margin: 0 0 0.8rem;
                            }
                        }

                        .offering_item_text {
                            h4 {
                                font-family: $secondaryfont;
                                font-size: 2.2rem;
                                font-weight: 600;
                                line-height: 3.3rem;

                                @media (max-width: 1579px) {
                                    font-size: 2rem;
                                    line-height: 3rem;
                                }

                                @media (max-width: 1479px) {
                                    font-size: 1.8rem;
                                    line-height: 2.8rem;
                                }
                            }

                            p {
                                font-family: $secondaryfont;
                                font-size: 1.6rem;
                                font-weight: 400;
                                line-height: 2.6rem;
                                margin-top: 1rem;
                                color: rgba($white, 0.8);

                                @media (max-width: 1579px) {
                                    margin-top: 0.6rem;
                                    font-size: 1.4rem;
                                    line-height: 2.4rem;
                                }

                                @media (max-width: 1479px) {
                                    margin-top: 0.4rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}