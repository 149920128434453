@import "../../../../assets/theme/_var.scss";

.work {
    padding: 8rem 0 10rem;
    overflow: clip;
    position: relative;
    z-index: 1;

    @media (max-width: 1579px) {
        padding: 5rem 0 5rem;
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        background-repeat: no-repeat;
        height: 100%;
        pointer-events: none;
    }

    &::after {
        width: 117.3rem;
        background-image: url("../../../../assets/images/how-it-works-right.png");
        right: 0;
        background-size: cover;
        background-position: 10% center;
        bottom: 0;
    }

    &::before {
        width: 105.3rem;
        background-image: url("../../../../assets/images/how-it-works-left.png");
        background-size: cover;
        background-position: center;
        top: 1.5rem;
        left: 0;
    }

    h2 {
        text-align: center;
        margin-bottom: 9rem;

        @media (max-width: 1579px) {
            margin-bottom: 6rem;
        }

        @media (max-width: 1479px) {
            margin-bottom: 4rem;
        }
    }

    .work_in {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @media (min-width: 1480px) {
            padding: 0 3.8rem;
        }

        @media (max-width: 899px) {
            flex-direction: column;
            max-width: 56rem;
            margin: 0 auto;
        }

        .work_box {
            width: 100%;
            max-width: 29.4rem;
            text-align: center;
            position: relative;
            z-index: 1;

            @media (max-width: 899px) {
                display: flex;
                max-width: 100%;
                text-align: left;

                &:not(:last-child) {
                    margin-bottom: 7rem;
                }
            }

            &:not(:last-child) {
                .line {
                    position: absolute;
                    top: 5rem;
                    left: calc(50% + 7.2rem);
                    transform: translateY(-50%);
                    background-image: linear-gradient(90deg, white, white 75%, transparent 75%, transparent 100%);
                    background-size: 1.6rem 0.1rem;
                    background-position: 0.2rem center;
                    width: calc(100% + 33% - 2.2rem);
                    height: 0.1rem;
                    opacity: 0.6;

                    @media (max-width: 1679px) {
                        width: calc(99% - 2.2rem);
                        top: 4rem;
                    }

                    @media (max-width: 1479px) {
                        width: 85%;
                        top: 3rem;
                    }

                    @media (max-width: 1199px) {
                        width: 66%;
                        left: calc(50% + 5.2rem);
                    }

                    @media (max-width: 899px) {
                        top: 6.4rem;
                        left: 3rem;
                        transform: translateX(-50%);
                        width: 1px;
                        height: 100%;
                        background: repeating-linear-gradient(to bottom,
                                transparent,
                                transparent 5px,
                                $white 5px,
                                $white 10px);
                    }

                    &::after,
                    &::before {
                        content: "";
                        width: 1rem;
                        height: 1rem;
                        border: 1px solid $white;
                        border-radius: 50%;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);

                        @media (max-width: 899px) {
                            content: unset;
                        }
                    }

                    &::before {
                        left: 100%;
                    }

                    &::after {
                        right: 100%;
                    }
                }
            }

            .work_box_icon {
                border: 1px solid transparent;
                border-radius: 2rem;
                background: $gradient2 padding-box, linear-gradient(176.46deg, rgba($white, 0.4) 2.91%, rgba(0, 0, 0, 1) 100.38%) border-box;
                width: 10rem;
                height: 10rem;
                display: flex;
                align-items: center;
                flex-shrink: 0;
                margin: 0 auto;
                justify-content: center;

                @media (max-width: 1579px) {
                    width: 8rem;
                    height: 8rem;
                }

                @media (max-width: 1479px) {
                    width: 6rem;
                    height: 6rem;
                    border-radius: 1.6rem;
                    padding: 1.6rem;
                }

                @media (max-width: 899px) {
                    margin: 0 2rem 0 0;
                }
            }

            p {
                font-family: $secondaryfont;
                font-size: 1.6rem;
                font-weight: 400;
                line-height: 2.6rem;
                color: rgba($white, 0.7);
                margin: 3rem 0 1.6rem;

                @media (max-width: 1579px) {
                    font-size: 1.4rem;
                    margin: 2.4rem 0 1rem;
                    line-height: 1.4;
                }

                @media (max-width: 1479px) {
                    margin: 2rem 0 0.8rem;
                }

                @media (max-width: 899px) {
                    margin: 0 0 0.8rem;
                }
            }

            h4 {
                font-family: $secondaryfont;
                font-size: 1.8rem;
                font-weight: 500;
                line-height: 2.7rem;

                @media (max-width: 1579px) {
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                }

                @media (max-width: 1479px) {
                    font-size: 1.4rem;
                }
            }
        }
    }
}