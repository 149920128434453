@import "../../../../assets/theme/_var.scss";

.banner {
    padding: 3.1rem 0 4.9rem;

    @media (max-width: 1579px) {
        padding: 3rem 0 5rem;
    }

    @media (max-width: 1479px) {
        padding: 4rem 0;
    }

    .container {
        max-width: 161.2rem;

        @media (max-width: 1679px) {
            max-width: 136rem;
        }

        @media (max-width: 1479px) {
            max-width: 116rem;
        }

        @media (max-width: 1199px) {
            max-width: 96rem;
        }
    }

    .banner_in {
        padding: 17.4rem 9rem;
        background: url("../../../../assets/images/banner.png") no-repeat center/cover;
        border-radius: 3rem;

        // @media (max-width: 1579px) {
        //     padding: 14rem 8rem;
        // }

        @media (max-width: 1679px) {
            padding: 4rem 6rem;
            height: calc(100dvh - 15rem);
            min-height: 33.4rem;
            max-height: 52rem;
            display: flex;
            align-items: center;
        }

        @media (max-width: 575px) {
            position: relative;
            z-index: 1;
            background-position: 80%;
            padding: 2.4rem;

            &::before {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient(358deg, rgb(9 2 47 / 79%), rgba(0, 0, 0, 0.3));
                z-index: -1;
                border-radius: inherit;
                pointer-events: none;
            }
        }

        .banner_box {
            max-width: 48rem;

            @media (max-width: 1579px) {
                max-width: 44rem;
            }

            @media (max-width: 1479px) {
                max-width: 40rem;
            }

            @media (max-width: 575px) {
                margin: 0 auto;
                text-align: center;
                max-width: 100%;
                width: 100%;
            }

            h1 {
                font-size: 6rem;
                font-weight: 700;
                line-height: 7.2rem;
                margin-bottom: 2.6rem;

                @media (max-width: 1579px) {
                    font-size: 5rem;
                    line-height: 1.4;
                }

                @media (max-width: 1479px) {
                    font-size: 4rem;
                }

                @media (max-width: 1365px) {
                    font-size: 3.4rem;
                }

                @media (max-width: 575px) {
                    font-size: 2.8rem;
                    margin-bottom: 2rem;
                }

                span {
                    color: $baseclr;
                }
            }

            p {
                font-family: $secondaryfont;
                font-size: 2rem;
                font-weight: 400;
                line-height: 3rem;
                color: rgba($white , 0.7);

                @media (max-width: 1579px) {
                    font-size: 1.8rem;
                    line-height: 2.8rem;
                }

                @media (max-width: 1479px) {
                    font-size: 1.6rem;
                    line-height: 2.6rem;
                }
            }
        }
    }
}