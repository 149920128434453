@import "../../../assets/theme/_var.scss";

.header {
    position: sticky;
    top: 0;
    z-index: 10;
    backdrop-filter: blur(0.8rem);
    border-bottom: 0.1rem solid rgba($white, 0.1);

    .header_in {
        padding: 2.6rem 0;
        display: flex;
        align-items: center;

        @media (max-width: 1579px) {
            padding: 1.6rem 0;
        }

        @media (max-width: 1479px) {
            padding: 1.2rem 0;
        }

        .logo {
            max-width: 16.5rem;

            @media (max-width: 1579px) {
                max-width: 16rem;
            }

            @media (max-width: 1479px) {
                width: 14rem;
            }
        }
    }
}