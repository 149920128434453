@import "../../../../assets/theme/_var.scss";

.about {
    padding: 5rem 0 19rem;

    @media (max-width: 1579px) {
        padding: 5rem 0 10rem;
    }

    @media (max-width: 991px) {
        padding: 5rem 0;
    }

    .row {
        margin: -1.8rem;

        >div {
            padding: 1.8rem;
        }

        @media (max-width: 1479px) {
            margin: -0.8rem;

            >div {
                padding: 0.8rem;
            }
        }
    }

    .about_box {
        border: 1px solid $borderclr;
        background: $gradient1;
        padding: 3.2rem 8.5rem 5.6rem;
        border-radius: 3rem;
        height: 100%;
        text-align: center;

        @media (max-width: 1479px) {
            padding: 2.4rem 6rem 4rem;
        }

        @media (max-width: 991px) {
            padding: 2rem;
        }

        .about_box_icon {
            max-width: 22rem;
            height: 15.4rem;
            object-fit: contain;

            @media (max-width: 1479px) {
                max-width: 14rem;
                height: 10rem;
            }

            &.leverage {
                width: 11.5rem;

                @media (max-width: 1479px) {
                    width: 7rem;
                }
            }
        }

        h3 {
            font-size: 3rem;
            font-weight: 700;
            line-height: 3.6rem;
            margin: 2.8rem auto 2rem;
            max-width: 45.2rem;
            width: 100%;

            @media (max-width: 1579px) {
                font-size: 2.6rem;
                margin: 2rem auto 1.6rem;
                max-width: 40rem;
            }

            @media (max-width: 1479px) {
                font-size: 2.2rem;
                line-height: 3rem;
                margin: 1.2rem auto 1rem;
                max-width: 32rem;
            }

            @media (max-width: 1365px) {
                font-size: 2rem;
                line-height: 1.4;
            }

            @media (max-width: 575px) {
                font-size: 1.6rem;
            }

            span {
                color: $baseclr;
            }
        }

        p {
            font-family: $secondaryfont;
            font-size: 2rem;
            font-weight: 400;
            line-height: 3rem;
            color: rgba($white, 0.7);

            @media (max-width: 1579px) {
                font-size: 1.8rem;
                line-height: 2.8rem;
            }

            @media (max-width: 1479px) {
                font-size: 1.6rem;
                line-height: 2.6rem;
            }

            @media (max-width: 575px) {
                font-size: 1.4rem;
            }

            span {
                font-weight: 600;
                color: rgba($white, 1);
            }
        }
    }
}