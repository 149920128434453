@import "../../../assets/theme/_var.scss";

.footer {
    margin-top: auto;
    padding: 3.6rem 0;
    background-color: rgba($white, 0.05);
    border-top: 1px solid $borderclr2;

    @media (max-width: 1579px) {
        padding: 2.4rem 0;
    }

    @media (max-width: 1479px) {
        padding: 1.8rem 0;
    }

    .footer_in {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
            max-width: 16.5rem;

            @media (max-width: 1579px) {
                max-width: 16rem;
            }

            @media (max-width: 1479px) {
                width: 14rem;
            }
        }

        .copyright {
            p {
                font-family: $otherfont;
                font-size: 1.8rem;
                font-weight: 400;
                line-height: 2.8rem;

                @media (max-width: 1579px) {
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                }

                @media (max-width: 1479px) {
                    font-size: 1.4rem;
                }
            }
        }
    }
}